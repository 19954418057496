import { RouterModule, Routes } from '@angular/router';
//import { LoginRGuardGuard } from './services/service.index'
import { PagesComponent } from './pages/pages.component';

import { LoginComponent } from './login/login.component';
import { LoginBaseComponent } from './loginbase/loginbase.component';
import { LoginRyComponent } from './loginry/loginry.component';
import { LoginTriComponent } from './logintrinitas/logintri.component';
import { ResetpwdComponent } from './login/resetpwd.component';
import { ResetpwdBaseComponent } from './loginbase/resetpwdbase.component';
import { ResetpwdryComponent } from './loginry/resetpwdry.component';
import { ResetpwdTriComponent } from './logintrinitas/resetpwdtri.component';
import { NopagefoundComponent } from './shared/nopagefound/nopagefound.component';
import { AccesoDenegadoComponent } from './shared/accesodenegado/accesodenegado.component';


const appRoutes: Routes = [
    { path: 'login', component: LoginComponent},
    { path: 'loginbase', component: LoginBaseComponent},
    { path: 'loginry', component: LoginRyComponent },
    { path: 'logintri', component: LoginTriComponent },
    { path: 'resetpwd/:token', component: ResetpwdComponent },
    { path: 'resetpwdbase/:token', component: ResetpwdBaseComponent },
    { path: 'resetpwdtri/:token', component: ResetpwdTriComponent },
    { path: 'resetpwdry/:token', component: ResetpwdryComponent },
    { path: 'accesodenegado', component: AccesoDenegadoComponent },
    { path: '**', component: NopagefoundComponent }
];


export const APP_ROUTES = RouterModule.forRoot( appRoutes, { useHash: true } );
