import { Component, OnInit } from '@angular/core';
import { SettingsService, UsuarioService } from '../../services/service.index';
import { Usuario } from '../../models/usuario.model';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  // usuario: Usuario;
   checked1: boolean;
   ajustes = {
    temaUrl: 'assets/css/colors/blue.css',
    tema: 'blue'
  };
   usuario = localStorage.getItem('usuario');
   email = localStorage.getItem('emailuser');
   usuariof = '';
   soyfactor = false;
   soybase = false;
   soyry = false;
   soytrin = false;
  constructor( public _usuarioService: UsuarioService,
               public _ajustes: SettingsService) {
               /* if ( localStorage.getItem('ajustes') ) {
                  this.ajustes = JSON.parse( localStorage.getItem('ajustes') );
                  if ( this.ajustes.tema === 'blue' ) {
                   // console.log('es false');
                    this.checked1 = false;
                  } else if ( this.ajustes.tema === 'blue-dark' ) {
                    this.checked1 = true;

                  }
                } */
                if (localStorage.getItem('quiensoy')) {
                  if (localStorage.getItem('quiensoy') === 'base') {
                    this.ajustes.tema = 'orangebase';
                    this.ajustes.temaUrl = 'assets/css/colors/orangebase.css';
                    this._ajustes.aplicarTema( this.ajustes.tema );
                  } else if (localStorage.getItem('quiensoy') === 'factor') {
                    this.ajustes.tema = 'blue';
                    this.ajustes.temaUrl = 'assets/css/colors/blue.css';
                    this._ajustes.aplicarTema( this.ajustes.tema );
                  } else if (localStorage.getItem('quiensoy') === 'rioyaqui') {
                    this.ajustes.tema = 'rioyaqui';
                    this.ajustes.temaUrl = 'assets/css/colors/rioyaqui.css';
                    this._ajustes.aplicarTema( this.ajustes.tema );
                  } else if (localStorage.getItem('quiensoy') === 'trinitas') {
                    this.ajustes.tema = 'trinitas';
                    this.ajustes.temaUrl = 'assets/css/colors/trinitas.css';
                    this._ajustes.aplicarTema( this.ajustes.tema );
                  }
                } 
               }

  ngOnInit() {
  this.soyfactor = false;
  this.soybase = false;
  this.soyry = false;
  this.soytrin = false;
  let quiensoy = localStorage.getItem('quiensoy' );

  switch (quiensoy) {
    case 'factor':
      this.soyfactor = true;
      break;
    case 'base':
      this.soybase = true;
      break;
    case 'rioyaqui':
      this.soyry = true;
      break;
    case 'trinitas':
      this.soytrin = true;
      break;
    default:
      this.soyfactor = true;
      break;
  }
  this.usuariof = this.usuario.slice(1, -1);
  let  email = this.email;
  }

  cambiarColor( tema: string) {  // , link: any ) {

    this.checked1 = !this.checked1;
    if (this.checked1) {
      tema = 'blue';
      this.checked1 = false;
    } else {
      tema = 'blue-dark';
      this.checked1 = true;
    }
    this._ajustes.aplicarTema( tema );

  }

}
