import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class SettingsService {

  ajustes: Ajustes = {
    temaUrl: 'assets/css/colors/blue.css',
    tema: 'blue'
  };

  constructor( @Inject(DOCUMENT) private _document ) {
    this.cargarAjustes();
  }

  guardarAjustes() {
    localStorage.setItem('ajustes', JSON.stringify( this.ajustes )  );
  }

  cargarAjustes() {

    if (localStorage.getItem('quiensoy')) {
      if (localStorage.getItem('quiensoy') === 'base') {
        this.ajustes.tema = 'orangebase';
        this.ajustes.temaUrl = 'assets/css/colors/orangebase.css';
        this.aplicarTema( this.ajustes.tema );
      } else if (localStorage.getItem('quiensoy') === 'rioyaqui') {
        this.ajustes.tema = 'rioyaqui';
        this.ajustes.temaUrl = 'assets/css/colors/rioyaqui.css';
        this.aplicarTema( this.ajustes.tema );
      } else if (localStorage.getItem('quiensoy') === 'trinitas') {
        this.ajustes.tema = 'trinitas';
        this.ajustes.temaUrl = 'assets/css/colors/trinitas.css';
        this.aplicarTema( this.ajustes.tema );
      }
    } else {
    if ( localStorage.getItem('ajustes') ) {
      this.ajustes = JSON.parse( localStorage.getItem('ajustes') );

      this.aplicarTema( this.ajustes.tema );

    }else {

      this.aplicarTema( this.ajustes.tema );
    }
    }

    

  }

  aplicarTema( tema: string ) {


    let url = `assets/css/colors/${ tema }.css`;
    this._document.getElementById('tema').setAttribute('href', url );

    this.ajustes.tema = tema;
    this.ajustes.temaUrl = url;

   // this.guardarAjustes();

  }

}

interface Ajustes {
  temaUrl: string;
  tema: string;
}

