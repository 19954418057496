import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { AwsService } from 'src/app/services/awsser/aws.service';
import swal2 from 'sweetalert2';
import { PagosService } from '../../services/pagos/pagos.service';
import { Router } from '@angular/router';
import { ContribuyentesService } from '../../services/contribuyentes/contribuyentes.service';
import * as moment from 'moment'
import { UsuarioService } from '../../services/usuario/usuario.service';
import { AltaSolicitudesService } from '../../services/altasolicitudes/altasolicitudes.service';

@Component({
  selector: 'app-afiliacion',
  templateUrl: './afiliacion.component.html',
  styleUrls: ['./afiliacion.component.css']
})
export class AfiliacionComponent implements OnInit {
   exppanel = false;
   disablepanel = true;
   idu: string;
   casados: string[] = ['Casado Sociedad conyugal', 'Casado Separación de bienes', 'Soltero'];
   escasado: any[] = [{ val: 'CA', mos: 'SI' },{val: 'SO', mos: 'NO'}];
   contributor_type: any;
   contributor_id = '';
   regUsuario = {
    contributor_id: this.contributor_id,
    name_denomination: '',
    legal_personality: '',
    legal_representative_name: '',
    birthdate_constitution_date: '',
    // fecha_prov: '',
    country_of_birth: '',
    rfc_homoclave: '',
    curp: '',
    nationality: '',
    gender: '',
    civil_status: '',
    fiel_number: '',
    proof_of_fiscal_situation: '',// numero de identificacion fiscal
    activity: '',
    profession: '',
    tax_residence: '',
    neighborhood: '',
    postal_code: '',
    municipality: '',
    state: '',
    chain_name: '',
    average_billing: '',
    current_credits: '',
    credit_type_amount: '',
    stock_structure: '',
    public_charge: '',
    public_charge_detail: '',
    relative_public_charge: '',
    relative_public_charge_detail: '',
    phone: '',
    email: '',
    marketing_autorization: '',
    pesos_usd_billing: '',
    insurance: 'N/A',
    deliver_account_status_home: false,
    deliver_account_status_internet: false,
    deliver_account_status_email: true,
    pesos_billing: 'false', 
    cat: '0', 
    tiie: '', 
    sofr: '', 
    credit_line_amount: '', 
    credit_line_amount_usd: '', 
    payment_total: '', 
    payment_total_usd: '', 
    term: '', 
    payment_due_date: '',
    cutoff_date: '', 
    opening_commission: '', 
    anticipate_payment_commission: '', 
    reca: '',
    reca_date: '',
    public_deed: '',
    deed_volume: '',
    deed_date: '',
    place: '',
    holder_licensee: '',
    notary_number: '',
    judicial_district: '',
    deed_state: '',
    state_signed_up: '',
    trade_folio: '',
    testimony_date: '',
    book: '',
    inscription_number: ''
   }
   regUsrForm = this._formBuilder.group({
    name_denomination: ['', Validators.required],
    legal_personality: ['', Validators.required],
    legal_representative_name: ['', []],
    birthdate_constitution_date: ['', []],
    // fecha_prov: ['', []],
    country_of_birth: ['', Validators.required],
    rfc_homoclave: ['', Validators.required],
    curp: ['', Validators.required],
    nationality: ['', Validators.required],
    gender: ['', Validators.required],
    civil_status: ['', Validators.required],
    fiel_number: ['', Validators.required],
    proof_of_fiscal_situation: ['', Validators.required],
    activity: ['', Validators.required],
    profession: ['', Validators.required],
    tax_residence: ['', Validators.required],
    neighborhood: ['', Validators.required],
    postal_code: ['', Validators.required],
    municipality: ['', Validators.required],
    state: ['', Validators.required],
    chain_name: ['', []],
    average_billing: ['', Validators.required],
    current_credits: ['', Validators.required],
    credit_type_amount: ['', []],
    stock_structure: ['', []],
    public_charge: ['', []],
    phone: ['', Validators.required],
    email: ['', Validators.required],
    public_charge_detail: ['', []],
    relative_public_charge: ['', []],
    relative_public_charge_detail: ['', []],
    marketing_autorization: ['', Validators.required],
    pesos_usd_billing: ['', []],
    insurance: ['', []],
    deliver_account_status_home: ['', []],
    deliver_account_status_internet: ['', []],
    deliver_account_status_email: ['', []],
    public_deed: ['', []],
    deed_volume: ['', []],
    deed_date: ['', []],
    place: ['', []],
    holder_licensee: ['', []],
    notary_number: ['', []],
    judicial_district: ['', []],
    deed_state: ['', []],
    state_signed_up: ['', []],
    trade_folio: ['', []],
    testimony_date: ['', []],
    book: ['', []],
    inscription_number: ['', []],
   })
  archivoNombres = [];
  archivoDocs = {};
  files = [];
  filesNames = [];

  resul = {};

  cadenas = [];
  generos = [
    {descripcion: 'Prefiero no responder', value: 'N/A'},
    {descripcion: 'Femenino', value: 'Femenino'},
    {descripcion: 'Masculino', value: 'Masculino'},
  ];
  nacionalidades = [
    {descripcion: 'Prefiero no responder', value: 'N/A'},
    {descripcion: 'Mexicano', value: 'Mexicano'},
    {descripcion: 'Extranjero', value: 'Extranjero'}
  ];

  cargado = false;
  paises: any[];
  estados: any;
  municipios: any;
  colonias: any[] = [];
  nombrecadena: any;
  manual = false;
  RFCFisica = false;
  RFCMoral = false;
  //nuevo manejo 
  regUsrForm2
  show_credit_type_amount = false;
  show_public_detail = false;
  show_relative_public_charge_detail = false;
  muestraTablaFinal = false;
  // fin nuevo manejo
  constructor(private _formBuilder: FormBuilder,
              private aws: AwsService,
              private pagosService: PagosService,
              private router: Router,
              private contribuyentesService: ContribuyentesService,
              private usuarioService: UsuarioService,
              private _solicitudesservice: AltaSolicitudesService) {
                
               }

  ngOnInit() {
    this.regUsrForm2 = this._formBuilder.group({
      legal_personality: ['',[]],
      legal_representative_name: ['',[]],
      name_denomination: ['', Validators.required],
      birthdate_constitution_date: ['', Validators.required],
      country_of_birth: ['', Validators.required],
      rfc_homoclave: ['', Validators.required],
      curp: ['', Validators.required],
      stock_structure: ['', []],
      nationality: ['', Validators.required],
      gender: ['', Validators.required],
      civil_status: ['', Validators.required],
      fiel_number: ['', Validators.required],
      proof_of_fiscal_situation: ['', Validators.required],
      activity: ['', Validators.required],
      profession: ['', Validators.required],
      tax_residence: ['', Validators.required],
      neighborhood: ['', Validators.required],
      postal_code: ['', Validators.required],
      municipality: ['', Validators.required],
      state: ['', Validators.required],
      chain_name: ['', []],
      phone: ['', Validators.required],
      email: ['', Validators.required],
      pesos_usd_billing: ['', Validators.required],
      average_billing: ['', Validators.required],
      current_credits: ['', Validators.required],
      credit_type_amount: ['', []],
      public_charge: ['', Validators.required],
      public_charge_detail: ['',[]],
      relative_public_charge: ['',Validators.required],
      relative_public_charge_detail: ['',[]],
      marketing_autorization: ['', Validators.required]
     });
    this.show_credit_type_amount = false;
    this.show_public_detail = false;
    this.show_relative_public_charge_detail = false;
    this.muestraTablaFinal = false;
    if (localStorage.getItem('id')) {
      this.idu = localStorage.getItem('id');
      this.usuarioService.getUsuario(this.idu).subscribe(resp => {
        if(resp[0].status == 'ACTIVO'){
          this.router.navigate(['/dashboard']);
        } 
      })
      this.pagosService.getSuplierUser(this.idu).subscribe(resp => {
        this.contributor_type = resp[0].contributor_type;
        this.contributor_id = resp[0].contributor_id;
        this.regUsuario.contributor_id = this.contributor_id;
        this.archivoNombres = [];
        if(this.contributor_type == 'PERSONA MORAL'){
          this.regUsuario.legal_personality = 'PM';
        }else if(this.contributor_type == 'PERSONA FISICA'){
          this.regUsuario.legal_personality = 'PFAE'
        }
        console.log(this.regUsuario.legal_personality)
        this.aws.getContributorDocuments(this.contributor_id).subscribe(resp => {
          resp['data'].forEach(d => {
            let doc = d.attributes;
            if(doc.status == 'PI'){
              this.archivoNombres.push(doc)
            }
          });
          this.cargado = true;
        })
      })
    }
    this.pagosService.getCadenas().subscribe( resp => {
      this.cadenas = resp;
} );

    this.aws.getCountriesD().subscribe( resp => { this.paises = resp; } );

    this.idu = localStorage.getItem('id');
          
    this._solicitudesservice.getCadenaProveedor(this.idu).subscribe( resp => {
      try{
        this.regUsuario.chain_name = resp[0].cadena;
      }catch{
        console.log('Sin cadena');
        this.regUsuario.chain_name = '';
        this.manual = true;
      }
    }, err => {
      console.log('Sin cadena');
      this.regUsuario.chain_name = '';
      this.manual = true;
    });
  }
  lpchange(e) {
    if (e === 'PM') {
      this.regUsuario.legal_personality = 'PM'
      this.regUsrForm2.addControl('legal_representative_name', new FormControl('', Validators.required))
    } else {
      this.regUsuario.legal_personality = 'PFAE'
      this.regUsrForm2.removeControl('legal_representative_name');
      this.regUsuario.legal_representative_name = '';
    }
  }
  credvigentes(e) {
    console.log(e)
    if (e === 'SI') {
      this.show_credit_type_amount = true;
      this.regUsrForm2.addControl('credit_type_amount', new FormControl('', Validators.required))
    } else {
      this.show_credit_type_amount = false;
      this.regUsrForm2.removeControl('credit_type_amount');
    }
  }
  cpublico(e) {
    console.log(e)
    if (e === 'SI') {
      this.show_public_detail = true;
      this.regUsrForm2.addControl('public_charge_detail', new FormControl('', Validators.required))
    } else {
      this.show_public_detail = false;
      this.regUsrForm2.removeControl('public_charge_detail');
      this.regUsuario.public_charge_detail = '';
    }
  }
  cpublicorelative(e) {
    console.log(e)
    if (e === 'SI') {
      this.show_relative_public_charge_detail = true;
      this.regUsrForm2.addControl('relative_public_charge_detail', new FormControl('', Validators.required))
    } else {
      this.show_relative_public_charge_detail = false;
      this.regUsrForm2.removeControl('relative_public_charge_detail');
      
    }
  }
  onSubmit(form: FormGroup) {
    this.regUsuario.name_denomination = form.get('name_denomination').value;
    this.regUsuario.birthdate_constitution_date = form.get('birthdate_constitution_date').value.toString();
    this.regUsuario.country_of_birth = form.get('country_of_birth').value;
    this.regUsuario.rfc_homoclave = form.get('rfc_homoclave').value;
    this.regUsuario.curp = form.get('curp').value;
    this.regUsuario.stock_structure = form.get('stock_structure').value;
    this.regUsuario.nationality = form.get('nationality').value;
    this.regUsuario.gender = form.get('gender').value;
    this.regUsuario.civil_status = form.get('civil_status').value;
    this.regUsuario.fiel_number = form.get('fiel_number').value;
    this.regUsuario.proof_of_fiscal_situation = form.get('proof_of_fiscal_situation').value;
    this.regUsuario.activity = form.get('activity').value;
    this.regUsuario.profession = form.get('profession').value;
    this.regUsuario.tax_residence = form.get('tax_residence').value;
    this.regUsuario.postal_code = form.get('postal_code').value;
    this.regUsuario.neighborhood = form.get('neighborhood').value;
    this.regUsuario.state = form.get('state').value;
    this.regUsuario.municipality = form.get('municipality').value;
    this.regUsuario.chain_name = form.get('chain_name').value;
    this.regUsuario.phone = form.get('phone').value;
    this.regUsuario.email = form.get('email').value;
    this.regUsuario.pesos_usd_billing = form.get('pesos_usd_billing').value;
    this.regUsuario.average_billing = form.get('average_billing').value;
    this.regUsuario.current_credits = form.get('current_credits').value;
    this.regUsuario.public_charge = form.get('public_charge').value;
    this.regUsuario.relative_public_charge = form.get('relative_public_charge').value;
    this.regUsuario.marketing_autorization = form.get('marketing_autorization').value;
    if (this.regUsuario.legal_personality === 'PM') {
      this.regUsuario.legal_representative_name = form.get('legal_representative_name').value;
    }
    if (this.show_credit_type_amount) {
      this.regUsuario.credit_type_amount = form.get('credit_type_amount').value;
    }
    if (this.show_public_detail) {
      this.regUsuario.public_charge_detail = form.get('public_charge_detail').value;
    }
    if (this.show_relative_public_charge_detail) {
      this.regUsuario.relative_public_charge_detail = form.get('relative_public_charge_detail').value;
    }
    Object.keys(this.regUsuario).forEach(k => {
      if(this.regUsuario[k] == ''){
        this.regUsuario[k] = 'N/A';
      }
    })
    this.muestraTablaFinal = true;
  }

  turn() {
    this.muestraTablaFinal = false;
  }

  getStates(e){
    console.log(e)
    this.aws.getStatesId(e).subscribe(resp => { this.estados = resp; })
  }

  getMunicipalities(e){
    console.log(e);
    let idE = this.estados.find(s => s.name == e);
    this.aws.getMunicipios(idE.id).subscribe(resp => { this.municipios = resp; })
  }

  getColonias(event) {
    this.aws.getColoniasxcp(event).subscribe(r => {this.colonias = r;})
   }

  // eventos para el control y vista para los archivos a subir //
  dragEnter(id: string) {
    document.getElementById(id).style.border = "dashed 2px blue";
  }
  dragLeave(id: string) {
    document.getElementById(id).style.border = "dashed 1px #979797";
  }
  drop(id: string) {
   document.getElementById(id).style.border = "dashed 1px #979797";
  }

  validaRFC(persona: string) {
    this.regUsuario.rfc_homoclave = this.regUsuario.rfc_homoclave.toUpperCase();

    if (persona === 'fisica') {
      const regex = /^[A-Z]{4}[0-9]{6}[A-Z0-9]{3}/;
      const RFCFisica = this.regUsuario.rfc_homoclave;
      const resultado = regex.test(RFCFisica);

      if (RFCFisica.length > 0) {
        if ( resultado === false ) {
          // document.getElementById('rfcFisica').focus();
          this.RFCFisica = true;
        } else {
          this.RFCFisica = false;
        }
    } else {
      this.RFCFisica = false;
    }
    }

    if (persona === 'moral') {
      const regex = /^[A-Z]{3}[0-9]{6}[A-Z0-9]{3}/;
      const RFCMoral = this.regUsuario.rfc_homoclave;
      const resultado = regex.test(RFCMoral);

      if (RFCMoral.length > 0) {
        if ( resultado === false ) {
          // document.getElementById('rfcmoral').focus();
          this.RFCMoral = true;
        } else {
          this.RFCMoral = false;
        }
    } else {
      this.RFCMoral = false;
    }
    }
  }

  async fileBrowseHandler( e: any, doc: string ) {
    this.resul[doc] = await this.callupload(e[0], this.idu, doc);
    let archivoKeys = Object.keys(this.archivoDocs);
    if(archivoKeys.length == this.archivoNombres.length){
      localStorage.setItem('Documents', JSON.stringify(this.resul));
      this.exppanel = true;
      this.disablepanel = false;
    }
    if(this.contributor_type == 'PERSONA MORAL' && archivoKeys.length == this.archivoNombres.length-1 && !this.archivoDocs.hasOwnProperty('Carta de asamblea')){
      localStorage.setItem('Documents', JSON.stringify(this.resul));
      this.exppanel = true;
      this.disablepanel = false;
    }
  }
  
  
  async callupload(file, idu, doc) {
    try {
      const r = await this.aws.uploadFile(file, idu)
      let resp = {
        status: true,
        resp: r,
        msg: 'Archivo subido correctamente'
      }
      this.archivoDocs[doc] = file;
      return resp
    } catch (error) {
      console.log('error', error);
      let resp = {
        status: false,
        resp: error,
        msg: 'Fallo al subir archivo'
      }
      swal2.fire('Error', 'Ocurrió algo en la carga', 'info')
      return resp
    }
  }
  
  async actualizaUrls(archivosNombres, archivosLocation) {
    let taresp: any[] = [];
    Object.keys(archivosLocation).forEach(k => {
      let id = archivosNombres.filter(n => n.name == k)[0].id
      try {
        this.aws.patchUrl(this.contributor_id, id, archivosLocation[k].resp.Location).subscribe(resp => {
          console.log(resp);
        }, err => {
          console.log(err);
        })
        let resp = {
          status: true,
          msg: k
        }
        taresp.push(resp);
      } catch (error) {
        console.log('error al actualizar liga', error)
        let resp = {
          status: false,
          resp: error,
          msg: k
        }
        taresp.push(resp);  
      }
      
    })
    return taresp;
  }
  
  uploadToBucket() {
    let archivoKeys = Object.keys(this.archivoDocs);
    let resul = {};
    if(archivoKeys.length < this.archivoNombres.length){
      swal2.fire('Atención', 'Faltan archivos por cargar', 'info');
    }else{
      swal2.fire('Subiendo archivos','','info');
      swal2.showLoading();

      for (let i = 0; i < archivoKeys.length; i++) {
        const doc = this.archivoDocs[archivoKeys[i]];
        this.callupload(doc, this.idu, archivoKeys[i]).then(r => {
          resul[archivoKeys[i]] = r;
          if(i == archivoKeys.length-1){
            console.log('si')
            localStorage.setItem('Documents', JSON.stringify(resul));
            this.exppanel = true;
            this.disablepanel = false;
            let archivossubidos = JSON.parse(localStorage.getItem('Documents'));
            this.actualizaUrls(this.archivoNombres, archivossubidos);
          }
        })
      }

    }

     
   }
  
  async regUsr() {
    swal2.showLoading();
    let params = {};
   /* Object.keys(this.regUsuario).forEach(k => {
      if(this.regUsuario[k] == ''){
        this.regUsuario[k] = 'N/A';
      }
    })
    this.regUsuario.birthdate_constitution_date = this.regUsuario.birthdate_constitution_date.toString(); */
    params['contributor_type'] = this.regUsuario.legal_personality;
    params['contributor_details'] = this.regUsuario;
    this.contribuyentesService.contributorDetails(params).subscribe(resp => {
      let archivossubidos = JSON.parse(localStorage.getItem('Documents'));
      if(archivossubidos){
        this.actualizaUrls(this.archivoNombres, archivossubidos);
      }
      let params = {};
      params['auth'] = {status: 'ACTIVO'};
      this.usuarioService.patchUser(this.idu, params).subscribe();
      swal2.fire('Éxito', 'Afiliación exitosa', 'success');
      this.router.navigate(['/dashboard']);
      swal2.close();
    }, err => {
      console.log('error', err);
      try{
        swal2.fire('Atención', err.error.error, 'info');
      }catch{
        swal2.fire('Atención', 'Algo salió mal', 'info');
      }
    })
   }

}
